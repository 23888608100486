@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Jost:wght@300;500');

:root {
  --color-blue-clear: #5EE7DF;
  --color-purple-clear: #B490CA;
  --color-purple-dark: #2A212F;
  --color-white: #FFFFFF;
  --color-grey: #EBEBEB;
  --color-cloudy: #F5F5F5;
  --color-font-black: #29212F;
  --color-light-gray: #D9D9D9;
  --color-background: #1E1E1E;
  --color-background-alt: #382D40;
}

/*** RESET ***/

html {
  scroll-behavior: smooth;
}

body {
  background: none;
  font-family: 'Jura';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-white);
}

.hidden {
  display: none;
}

a,
a:hover {
  text-decoration: none;
}

/*** HOME-SCREEN ***/

#home-screen {
  height: 100vh;
  background-color: var(--color-font-black);
  position: relative;
  overflow: hidden;
}

#home-screen h1 {
  font-style: normal;
  /* font-size: 64px; */
  font-weight: 600;
  line-height: 50px;
}

#home-screen .dropstart ul {
  padding-left: 8px;
  padding-right: 8px;
  min-width: 210px;
}

#home-screen .dropstart ul li {
  padding-top: 8px;
  padding-bottom: 8px;
}

#home-screen .dropstart ul li a:hover {
  color: var(--color-blue-clear);
}

@media only screen and (max-width: 768px) {
  #home-screen h1 {
      font-size: 26px;
      line-height: 31px;
      text-align: center;
  }

  .img-banner {
      width: 280px;
  }
}

.navbar {
  padding-top: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid #231F2033;
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .navbar {
      padding-top: 24px;
      padding-bottom: 40px;
  }
}

.logo-investing {
  padding-left: 12px;
}

.scrollnextsection {
  position: absolute;
  left: 50%;
  bottom: 24px;
}

.demo-bg {
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  pointer-events: none;
}

.banner-img {
  width: 642px;
}

.banner {
  height: 100vh;
}


/*** A PLATFORMA ***/

#platform {
  min-height: 100vh;
  background: radial-gradient(173.7% 324.28% at 117.66% -36.3%, var(--color-purple-clear) 0%, #382D40 66.67%);
  position: relative;
  overflow: hidden;
}

#platform .container {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.background-mdi-finance {
  background-color: #B490CA;
}

.img-platform {
  position: absolute;
  pointer-events: none;
  margin-top: 10vh;
  width: 40vw;
  top: 40%;
  right: 0;
  transform: translate(0, -50%);
}

@media only screen and (max-width: 768px) {
  .img-platform {
      position: initial;
      pointer-events: none;
      margin-top: 0;
      width: unset;
      top: unset;
      right: unset;
      transform: unset;
  }
}


/*** FUNCIONALIDADES ***/

#functionalities {
  min-height: 100vh;
  background-color: var(--color-background-alt);
}

.card-title {
  font-weight: 600;
  font-size: 20px;
  color: var(--color-purple-clear);
  text-align: left;
  word-break: break-word;
  margin-bottom: 8px;
}

.card-text {
  font-weight: 600;
  color: var(--color-white);
  text-align: left;
  word-break: break-word;
}


/*** SOBRE ***/

#about {
  background-color: var(--color-cloudy);
  color: var(--color-font-black);
}

#about .about-paragraph {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

#about .about-info {
  font-weight: 600;
  font-size: 28px;
}

#about .about-info small {
  font-weight: 400;
  font-size: 14px;
}


/*** CLIENTES ***/

#clients {
  background-color: var(--color-white);
  color: var(--color-font-black);
}


#clients .client-image {
  clip-path: circle();
}

#clients .clients-info {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  line-height: 29px;
}

#clients .clients-names {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}


/*** SOLICITE UM ORÇAMENTO ***/

#contact {
  background-color: var(--color-background-alt);
}

.btn-custom,
.btn-custom:active,
.btn-custom:visited {
  background-color: var(--color-blue-clear) !important;
}

.btn-custom:hover {
  background-color: #3CE2D7 !important;
  color: var(--color-font-black);
}


/*** FOOTER ***/

#footer {
  background-color: var(--color-font-black);
  color: var(--color-light-gray);
}

#footer .company-info {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

}

.nav {
  --bs-nav-link-color: var(--color-light-gray);
  --bs-nav-link-hover-color: var(--color-blue-clear);
}

.social-links a {
  margin-left: 18px;
}

@media only screen and (max-width: 768px) {
  #footer .logo-inv-hub {
      width: 174x;
  }

  #footer .company-info {
      font-size: 14px;
      line-height: 17px;
  }

}